<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="ajustesOptimizadorRef" :fields="this.ajustes_optimizador.fields"
          :card="this.ajustes_optimizador.card">

        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <BrunaForm ref="ajustesCapacidadesRef" :fields="this.ajustes_capacidades.fields"
          :card="this.ajustes_capacidades.card">

        </BrunaForm>
      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <BrunaForm ref="ajustesRef" :fields="this.ajustes_mezcla_objetivo.fields"
          :card="this.ajustes_mezcla_objetivo.card">

        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title>{{ $t("Ajustes de Variables") }}</b-card-title>
              <b-card-sub-title>{{
                $t(
                  "Selecciona los rangos de valores de cada variable que se usaran en la optimizaciones automáticas"
                )
              }}</b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
                </li>
              </ul>
            </div>
          </div>
          <div>
            <b-sidebar id="sidebar-1" title="Administración de Pilas" shadow bg-variant="dark" text-variant="light"
              width="450px" right backdrop>
              <div class="px-2 py-2">
                <p>Aquí se puede configurar las administraciones de pilas:</p>
                <ul>
                  <li>Activar o desactivar los 3 rango de pila para CV14.</li>
                  <li>Configurar el mínimo y máximo en cada tramo de apilamiento (3 tramos por pila, cada uno con su rango).</li>
                  <li>Los tramos configurados para cada pila, son los valores predefinidos.</li>
                </ul>
                <p>Para <strong>guardar los cambios</strong> después de configurar todos los parámetros que se desean,
                  hacer clic en el botón <strong>Guardar Ajustes</strong></p>
              </div>
            </b-sidebar>
          </div>
          <b-card-body style="width: 100%">

            <div v-for="metric in input_variables" :key="metric.name">
              <b-row style="margin-top: 3rem; margin-bottom: 1rem">
                <b-col style="display: flex;">
                  <h4 class="mr-2">{{ metric.name }}</h4>
                  <b-form-checkbox v-model="metric.editable" switch></b-form-checkbox>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
              <b-row v-for="m in metric.data" :key="m.name" style="padding-bottom: 1rem">

                <b-col lg="3" xl="3">
                  {{ m.name }}
                </b-col>
                <b-col lg="6" xl="6">
                  <vue-slider v-model="m.range" :min="m.minimo" :max="m.maximo" :interval="0.01" :marks="[0, 100]"
                    direction="ltr" :disabled="!metric.editable" :enable-cross="false" class="mb-2 vue-slider-secondary">
                  </vue-slider>
                </b-col>
              </b-row>
            </div>
          </b-card-body>

        </b-card>

      </b-col>
    </b-row>





    <b-row>
      <b-col>
        <b-button size="md" @click="saveAjustes" variant="primary">Guardar Ajustes</b-button>
      </b-col>
    </b-row>


  </section>
</template>
      
<script>


import VueSlider from 'vue-slider-component'
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";


import useApiServices from '@/services/useApiServices.js';


import axios from "@axios";
var qs = require('qs');

import {
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {

    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,

    BrunaForm,
    VueSlider,
  },

  methods: {

    saveAjustes() {

      this.$refs.ajustesRef.validate().then((value) => {

        if (value) {

          let ajustes_administracion_pilas = {}

          this.input_variables.forEach(input_variable => {
            ajustes_administracion_pilas[input_variable.name] = input_variable.data
          })

          // console.log('here: ', ajustes_administracion_pilas )

          let data = {
            ajustes_optimizador: this.ajustes_optimizador.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
            ajustes_capacidades: this.ajustes_capacidades.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
            ajustes_mezcla_objetivo: this.ajustes_mezcla_objetivo.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
            ajustes_administracion_pilas: ajustes_administracion_pilas
            // ajustes_administracion_pilas: this.ajustes_administracion_pilas.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
          };

          console.log("save", data);

        }
      })




    },

    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },
  },

  mounted() {

    let self = this;
    //Se escucha a la llegada de las notificaciones de pusher

  },


  created() {

  },


  data() {

    let debug = (this.$route.query.debug == '1')


    return {

      input_variables: [
        {
          name: 'CV14 Pila 1',
          editable: true,
          data: [
            {
              name: 'P1 Tramo 1',
              range: [
                0.011,
                10
              ],
              value: 0
            },
            {
              name: 'P1 Tramo 2',
              range: [
                11,
                20
              ],
              value: 0
            },
            {
              name: 'P1 Tramo 3',
              range: [
                21,
                31
              ],
              value: 0
            }]
        },
        {
          name: 'CV14 Pila 2',
          editable: true,
          data: [
            {
              name: 'P2 Tramo 1',
              range: [
                32,
                41
              ],
              value: 0
            },
            {
              name: 'P2 Tramo 2',
              range: [
                42,
                51
              ],
              value: 0
            },
            {
              name: 'P2 Tramo 3',
              range: [
                51,
                100
              ],
              value: 0
            }]
        },
        {
          name: 'CV14 Pila 3',
          editable: false,
          data: [
            {
              name: 'P3 Tramo 1',
              range: [
                0,
                100
              ],
              value: 0
            },
            {
              name: 'P3 Tramo 2',
              range: [
                0,
                100
              ],
              value: 0
            },
            {
              name: 'P3 Tramo 3',
              range: [
                0,
                100
              ],
              value: 0
            }]
        },
      ],
      showOverlay: false,

      ajustes_optimizador: {
        card: {
          title: "Simulador",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Simulador",
            body: "<p>Aquí se pueden configurar el N° Máximo de Generaciones y Tipo Función Objetivo que aparecerá por omisión en Menú - Predicciones - Simulación de escenarios. Recordar que mientras más generaciones se quieran, más tiempo demorará en realizar la simulación.</p>"
          }
        },
        fields: [

          {
            label: "N° Máximo de Generaciones",
            type: "select",
            id: 'numero_max_generaciones',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 100, text: "100 generaciones" },
              { value: 200, text: "200 generaciones" },
              { value: 300, text: "300 generaciones" },
              { value: 400, text: "400 generaciones" },
              { value: 500, text: "500 generaciones" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 300
          },



          {
            label: "Tipo Función Objetivo",
            type: "select",
            id: 'tipo_funcion_objetivo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'default', text: "Maximizar FeMag y %Mag Mezcla" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'default'
          },
        ]
      },


      ajustes_capacidades: {
        card: {
          title: "Capacidades",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Capacidades",
            body: '<p>Aquí se pueden configurar los valores por omisión del simulador para:</p><ul><li>Flujos mínimos</li><li>Flujos máximos</li><li>Flujos críticos para cada correa</li><li>Flujo objetivo deseado</li></ul>'
          }
        },
        fields: [

          {
            label: "CV12 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV12 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "CV14 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV14 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "Flujo Total Mínimo Critico (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min_critico',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 700
          },

          {
            label: "Flujo Total Mínimo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 950
          },

          {
            label: "Flujo Total Máximo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },



        ]
      },



      ajustes_mezcla_objetivo: {
        card: {
          title: "Mezcla Objetivo",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Ajustes Mezcla Objetivo",
            body: '<p>Aquí se pueden configurar los valores por omisión objetivos tanto de magnetismo como de calidades para la realización de mezcla.</p>'
          }
        },
        fields: [

          {
            label: "FeMag Mezcla Mínimo",
            type: "input",
            id: 'femag_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 31
          },

          {
            label: "FeMag Mezcla Máximo",
            type: "input",
            id: 'femag_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 33
          },


          {
            label: "Magnetismo Mezcla Mínimo",
            type: "input",
            id: 'magnetismo_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 86
          },

          {
            label: "Magnetismo Mezcla Máximo",
            type: "input",
            id: 'magnetismo_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 89
          },




        ]
      },


      ajustes_administracion_pilas: {
        card: {
          title: "Administración de Pilas",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Administración de Pilas",
            body: '<p>Aquí se puede configurar las administraciones de pilas:</p><ul><li>Activar o desactivar los 3 rango de pila para CV14</li><li>Configurar el mínimo y máximo en cada rango de pilas</li></ul><p>Para <strong>guardar los cambios</strong> después de configurar todos los parámetros que se desean, hacer clic en el botón <strong>Guardar Ajustes</strong></p>'
          }
        },
        /* fields: [

          {
            label: "CV14 Pila 1 Estado",
            type: "select",
            id: 'cv14_pila_1_status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'activado', text: "Activado" },
              { value: 'desactivado', text: "Desactivado" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'activado'
          },

          {
            label: "P1 Tramo 1 Mínimo",
            type: "input",
            id: 'cv14_pila_1_t_1_minimo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0.011
          },

          {
            label: "P1 Tramo 1 Máximo",
            type: "input",
            id: 'cv14_pila_1_t_1_maximo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 10
          },
          {
            label: "",
            type: "hidden",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

          {
            label: "P1 Tramo 2 Mínimo",
            type: "input",
            id: 'cv14_pila_1_t_2_minimo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 11
          },

          {
            label: "P1 Tramo 2 Máximo",
            type: "input",
            id: 'cv14_pila_1_t_2_maximo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 20
          },
          {
            label: "",
            type: "hidden",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

          {
            label: "P1 Tramo 3 Mínimo",
            type: "input",
            id: 'cv14_pila_1_t_3_minimo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 21
          },

          {
            label: "P1 Tramo 3 Máximo",
            type: "input",
            id: 'cv14_pila_1_t_3_maximo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 31
          },
          {
            label: "CV14 Pila 2 Estado",
            type: "select",
            id: 'cv14_pila_2_status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'activado', text: "Activado" },
              { value: 'desactivado', text: "Desactivado" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'activado'
          },

          {
            label: "P2 Tramo 1 Mínimo",
            type: "input",
            id: 'cv14_pila_2_t_1_minimo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 32
          },

          {
            label: "P2 Tramo 1 Máximo",
            type: "input",
            id: 'cv14_pila_2_t_1_maximo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 41
          },
          {
            label: "",
            type: "hidden",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
          {
            label: "P2 Tramo 2 Mínimo",
            type: "input",
            id: 'cv14_pila_2_t_2_minimo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 42
          },

          {
            label: "P2 Tramo 2 Máximo",
            type: "input",
            id: 'cv14_pila_2_t_2_maximo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 51
          },
          {
            label: "",
            type: "hidden",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
          {
            label: "P2 Tramo 3 Mínimo",
            type: "input",
            id: 'cv14_pila_2_t_3_minimo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 52
          },

          {
            label: "P2 Tramo 3 Máximo",
            type: "input",
            id: 'cv14_pila_2_t_3_maximo',
            rules: 'required|numeric2',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 100
          },

          {
            label: "CV14 Pila 3 Estado",
            type: "select",
            id: 'cv14_pila_3_status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'activado', text: "Activado" },
              { value: 'desactivado', text: "Desactivado" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'desactivado'
          },

          {
            label: "P3 Tramo 1 Mínimo",
            type: "input",
            id: 'cv14_pila_3_t_1_minimo',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },

          {
            label: "P3 Tramo 1 Máximo",
            type: "input",
            id: 'cv14_pila_3_t_1_maximo',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 100
          },
          {
            label: "",
            type: "hidden",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
          {
            label: "P3 Tramo 2 Mínimo",
            type: "input",
            id: 'cv14_pila_3_t_2_minimo',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },

          {
            label: "P3 Tramo 2 Máximo",
            type: "input",
            id: 'cv14_pila_3_t_2_maximo',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 100
          },
          {
            label: "",
            type: "hidden",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },
          {
            label: "P3 Tramo 3 Mínimo",
            type: "input",
            id: 'cv14_pila_3_t_3_minimo',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },

          {
            label: "P3 Tramo 3 Máximo",
            type: "input",
            id: 'cv14_pila_3_t_3_maximo',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 100
          },
        ] */
      },
    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
      
<style lang="scss" >
.badge {
  font-size: 85% !important;
}

.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        